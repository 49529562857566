import React, { useRef, useEffect, useState } from 'react';

function SubcategoryEntity({ record, canvasRef, isHovered, onMouseEnter, onMouseLeave, onClick }) {
    const entityRef = useRef(null);
    const [isFadeIn, setIsFadeIn] = useState(false);

    useEffect(() => {
        const handleLoad = () => {
            if (canvasRef.current && entityRef.current) {
                const canvasWidth = canvasRef.current.offsetWidth;
                const canvasHeight = canvasRef.current.offsetHeight;
                const entityWidth = entityRef.current.offsetWidth;
                const entityHeight = entityRef.current.offsetHeight;

                const maxX = canvasWidth - entityWidth;
                const maxY = canvasHeight - entityHeight;

                const initialX = Math.random() * maxX;
                const initialY = Math.random() * maxY;

                entityRef.current.style.transform = `translate(${initialX}px, ${initialY}px)`;
                entityRef.current.setAttribute('data-x', initialX);
                entityRef.current.setAttribute('data-y', initialY);

                // Trigger the fade-in by setting isFadeIn to true
                setIsFadeIn(true);
            }
        };

        const imgElement = entityRef.current.querySelector('img');
        if (imgElement) {
            imgElement.onload = handleLoad;
            // If the image is already loaded (e.g., from cache), call handleLoad immediately
            if (imgElement.complete) {
                handleLoad();
            }
        }
    }, [canvasRef, record.id]);

    const fadeInStyle = {
        opacity: isFadeIn ? 1 : 0,
        transition: 'opacity 0.5s ease-in-out',
    };

    return (
        <div ref={entityRef} className='draggable-container'
             id={`container-${record.id}`}
             onMouseEnter={onMouseEnter}
             onMouseLeave={onMouseLeave}
             onClick={onClick}
             style={{
                 cursor: 'move',
                 position: 'absolute',
                 transformOrigin: 'top left',
                 ...fadeInStyle,
                 touchAction: 'none',
                 userSelect: 'none',

             }}>
            <img
                id={`image-${record.id}`}
                src={record.imageUrl}
                alt={record.name}
                className="draggable"
                style={{
                    width: '130px',
                    height: 'auto',
                    transformOrigin: 'top left',
                    borderRadius: '8px', // Add border radius for rounded corners

                }}
            />
            <span style={{
                position: 'absolute',
                bottom: '-25px',
                left: '50%',
                transform: 'translateX(-50%)',
                padding: '5px 10px',
                fontSize: '12px',
                whiteSpace: 'nowrap',
                display: isHovered ? 'block' : 'none'
            }}>
                {record.name}
            </span>
        </div>
    );
}

export default SubcategoryEntity;






