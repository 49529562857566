import { useState, useEffect } from 'react';
import Airtable from 'airtable';
import questionMarkBox from '../local_images/question_mark_box.png'

const useSubcategoryData = (category, subcategory) => {
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;
        const baseId = process.env.REACT_APP_AIRTABLE_BASE_ID;
        const base = new Airtable({ apiKey }).base(baseId);
        const tableId = process.env.REACT_APP_AIRTABLE_TABLE_ID;

        // Construct a filter formula
        const filterByFormula = `AND({Category} = '${category}', {Subcategory} = '${subcategory}')`;

        base(tableId).select({
            filterByFormula,
            fields: ["Name", "Main Photograph ", "Year ", "Record Description", "Collaging Images", "Category", "Subcategory"],
        }).all().then(records => {
            //console.log("All Records fetched:", records); // Log the raw data fetched from Airtable

            setRecords(records.map(record => ({
                id: record.id,
                name: record.fields.Name,
                imageUrl: record.fields["Main Photograph "] && record.fields["Main Photograph "][0] 
                  ? record.fields["Main Photograph "][0].thumbnails.large.url 
                  : questionMarkBox,
                allImageUrls: record.fields["Main Photograph "] 
                  ? record.fields["Main Photograph "].map(image => image.thumbnails.large.url) 
                  : [questionMarkBox],
                year: record.fields["Year "],
                description: record.fields["Record Description"],
                collagingImgUrls: record.fields["Collaging Images"] 
                  ? record.fields["Collaging Images"].map(image => image.thumbnails.large.url) 
                  : [],
                category: record.fields["Category"],
                subcategory: record.fields["Subcategory"]
              })));
            setLoading(false);
        }).catch(err => {
            console.error('Error:', err);
            setError(err);
            setLoading(false);
        });

    }, [category, subcategory]);

    return { records, loading, error };
};

export default useSubcategoryData;




