import React from 'react';
import inf_screengrab1 from '../../local_images/inf_screengrab1.gif';
import inf_screengrab2 from '../../local_images/inf_screengrab2.gif';
import int_screengrab3 from '../../local_images/int_screengrab3.gif';
import int_screengrab4 from '../../local_images/int_screengrab4.gif';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faHandSparkles } from '@fortawesome/free-solid-svg-icons';

import { createPortal } from 'react-dom';

import './index.css';

function TutorialModal({ isOpen, onClose }) {
    if (!isOpen) return null;

    const handleClose = () => {
        onClose();
    };

    return createPortal(
        <div onClick={onClose} className="modal-overlay" style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.4)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '999999',
            cursor: 'alias'

        }}>
            <div className="modal-content"
                style={{
                    backgroundColor: '#5abfc0',
                    padding: '20px',
                    borderRadius: '8px',
                    maxWidth: '90%',
                    maxHeight: '93%',
                    overflowY: 'auto',
                    cursor: 'default'

                }} onClick={(e) => e.stopPropagation()}>
                {/* X FOR CLOSING MODAL */}
                <div style={{
                    position: 'absolute',
                    top: '20px',
                    right: '30px',
                    fontSize: '25px',
                    cursor: 'pointer',
                }} onClick={handleClose}>
                    &#10005;
                </div>
                <div style={{ textAlign: 'center', maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
                    <h1 style={{ fontSize: '2.8rem', fontFamily: 'Avenir', marginBottom: '-10px', marginTop: "10px" }}>Tutorial</h1>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '40px' }}>
                        {/* INTERACTIVE */}
                        <div style={{ flex: '1 1 50%', marginBottom: '20px', paddingRight: '20px', paddingLeft: '20px', border: '1px solid #ccc', borderRadius: '5px', padding: '20px' }}>
                            <h2 style={{ fontSize: '1.8rem', marginBottom: '10px' }}>Interactive<FontAwesomeIcon icon={faHandSparkles} style={{ marginLeft: '6px' }} /></h2>
                            <p style={{ fontSize: '1.2rem', textAlign: 'left', marginBottom: '10px' }}>
                                Create a collage using fragments from our archive material.
                            </p>
                            <div style={{ marginBottom: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '20px' }}>
                                <p style={{ fontSize: '1rem', textAlign: 'left', marginBottom: '5px' }}>Drag a "fragment" from the carousel to add it to your collage.</p>
                                <img src={int_screengrab3} alt="Interactive GIF 3" style={{ width: '100%', height: 'auto' }} />
                            </div>
                            <div style={{ marginBottom: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '20px' }}>
                                <p style={{ fontSize: '1rem', textAlign: 'left', marginBottom: '5px' }}>Click a fragment on the collage to see information about the fragment's source.</p>
                                <img src={int_screengrab4} alt="Interactive GIF 4" style={{ width: '100%', height: 'auto' }} />
                            </div> 
                        </div>

                        <div style={{ width: "10px", height: "10px" }}></div>

                        {/* INFORMATIONAL */}
                        <div style={{ flex: '1 1 50%', marginBottom: '20px', paddingRight: '20px', paddingLeft: '20px', border: '1px solid #ccc', borderRadius: '5px', padding: '20px' }}>
                            <h2 style={{ fontSize: '1.8rem', marginBottom: '10px' }}>Informational <FontAwesomeIcon icon={faCircleInfo} style={{ marginLeft: '4px' }} /></h2>
                            <p style={{ fontSize: '1.2rem', textAlign: 'left', marginBottom: '10px' }}>
                                Explore the history of Edmonia Lewis and her work on the Art, Context, Place, and Stewards pages.
                            </p>
                            <div style={{ marginBottom: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '20px' }}>
                                <p style={{ fontSize: '1rem', textAlign: 'left', marginBottom: '5px' }}>Click on an item to open up an informational panel.</p>
                                <img src={inf_screengrab1} alt="Informational GIF 1" style={{ width: '100%', height: 'auto' }} />
                            </div>
                            <div style={{ marginBottom: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '20px' }}>
                                <p style={{ fontSize: '1rem', textAlign: 'left', marginBottom: '5px' }}>At the bottom of the info panel you may choose to select a "fragment" to add to your collage.</p>
                                <img src={inf_screengrab2} alt="Informational GIF 2" style={{ width: '100%', height: 'auto' }} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
}

export default TutorialModal;
