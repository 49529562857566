import { useState, useEffect } from 'react';
import Airtable from 'airtable';

const useSidebarData = () => {
    const [categories, setCategories] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;
        const baseId = process.env.REACT_APP_AIRTABLE_BASE_ID;
        const tableId = process.env.REACT_APP_AIRTABLE_TABLE_ID;

        const base = new Airtable({ apiKey }).base(baseId);

        base(tableId).select().all().then(records => {
            // category map is created here
            const categoryMap = records.reduce((acc, record) => {
                const { Category, Subcategory } = record.fields;

                const categoryString = String(Category);
                const subcategoryString = String(Subcategory);

                if (!acc[categoryString]) {
                    acc[categoryString] = new Set();
                }

                if (subcategoryString) {
                    acc[categoryString].add(subcategoryString);
                }

                return acc;
            }, {});

            // category map is used here to create category object, which
            // excludes the "Altar" category from the final object
            const categoryObject = Object.keys(categoryMap).reduce((obj, key) => {
                if (key !== 'Altar' &&
                    key !== 'undefined') {
                    obj[key] = Array.from(categoryMap[key]);
                }
                return obj;
            }, {});

            setCategories(categoryObject);
            setIsLoading(false);
        }).catch(err => {
            console.error('Error fetching data:', err);
            setError(err);
            setIsLoading(false);
        });
    }, []);

    return { categories, isLoading, error };
};

export default useSidebarData;




