import Airtable from 'airtable';

const fetchFragmentUrl = async (name, imageIndex) => {
  const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;
  const baseId = process.env.REACT_APP_AIRTABLE_BASE_ID;
  const base = new Airtable({ apiKey }).base(baseId);
  const tableId = process.env.REACT_APP_AIRTABLE_TABLE_ID;

  const records = await base(tableId).select({
    fields: ["Name", "Collaging Images", "Main Photograph "],
    filterByFormula: `{Name} = "${name}"`
  }).all();

  if (records.length === 0) {
    throw new Error(`No records found for name: ${name}`);
  }
  //console.log("records:", records[0].fields["Collaging Images"][imageIndex]);

  const record = records[0];
  const images = record.fields["Collaging Images"] || [];
  const mainPhoto = record.fields["Main Photograph "] && record.fields["Main Photograph "][0];

  if (imageIndex >= images.length) {
    throw new Error(`No image found at index: ${imageIndex} for name: ${name}`);
  }

  return {
    largeThumbnailURL: images[imageIndex]?.thumbnails.large.url || null,
    mainPhoto: mainPhoto?.thumbnails.large.url || null,

  };
};

export default fetchFragmentUrl;
