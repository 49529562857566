import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './index.css';

const getColorByCategory = (category) => {
    const opacity = 1.0;
    switch (category.toLowerCase()) {
        case 'art':
            return `rgba(134, 64, 0, ${opacity})`;
        case 'context':
            return `rgba(192, 146, 125, ${opacity})`;
        case 'place':
            return `rgba(207, 96, 89, ${opacity})`;
        case 'stewards':
            return `rgba(147, 154, 121, ${opacity})`;
        default:
            return `rgba(200, 200, 200, ${opacity})`;
    }
};

const lightenColor = (rgba, percent) => {
    const [r, g, b, a] = rgba.match(/\d+/g).map(Number);
    const newR = Math.min(255, r + (255 - r) * percent);
    const newG = Math.min(255, g + (255 - g) * percent);
    const newB = Math.min(255, b + (255 - b) * percent);
    return `rgba(${newR}, ${newG}, ${newB}, ${a})`;
};

function SubcategoryEntityModal({ isOpen, onClose, record }) {
    // State to track loaded images
    const [loadedImages, setLoadedImages] = useState(0);
    const [selectedFragment, setSelectedFragment] = useState(null);

    const backgroundColor = getColorByCategory(record.category);
    const lightBackgroundColor = lightenColor(backgroundColor, 0.4); // Lighten by percentage 0.0 to 1.0


    const navigate = useNavigate();

    useEffect(() => {
        // Reset the loaded image count and selected fragment URL every time the modal is opened or the record changes
        setLoadedImages(0);
        setSelectedFragment(null);
    }, [isOpen, record]);

    // Function to handle image load
    const handleImageLoaded = () => {
        setLoadedImages(prev => prev + 1);
    };

    const handleClose = () => {
        setSelectedFragment(null);
        onClose();
    };

    const handleModalContentClick = (event) => {
        // If the click is not on a fragment, deselect the fragment
        if (!event.target.closest('.fragment')) {
            setSelectedFragment(null);
        }
    };

    const handleFragmentClick = (url, index, event) => {
        event.stopPropagation(); // Prevent the overlay click handler from being triggered
        setSelectedFragment({ url, index });
    };

    const handleAddToCollage = () => {
        if (selectedFragment) {
            //console.log('in modal, what does record contain?', record);
            const fragmentData = {
                largeThumbnailURL: selectedFragment.url,
                fragmentIndex: selectedFragment.index,

                recordName: record.name,
                recordDescription: record.description,
                recordMainPhoto: record.imageUrl,
                recordCategory: record.category,
                recordSubcategory: record.subcategory,
            };
            //console.log('fragmentData', fragmentData);
            localStorage.setItem('fragmentDataForCollage', JSON.stringify(fragmentData));
            navigate('/collage');

        }
    };

    if (!isOpen || !record) return null;

    // Determine the total number of images that need to load
    const totalImages = (record.allImageUrls?.length || 0) + (record.collagingImgUrls?.length || 0);

    return (
        <div onClick={handleClose} className="modal-overlay" style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.4)', // dim the background
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'auto',
            zIndex: '99999',
            cursor: 'alias'

        }}>
            <div className='modal-content'
                style={{
                    backgroundColor: backgroundColor,
                    color: 'black',
                    paddingTop: '40px',
                    padding: '40px',
                    borderRadius: '8px',
                    position: 'relative',
                    minWidth: '300px',
                    overflowY: 'auto',
                    boxSizing: 'border-box',
                    display: loadedImages === totalImages ? 'block' : 'none',  // Only display content when all images are loaded
                    maxWidth: "90%",
                    maxHeight: "93%",
                    boxShadow: `0 0 40px ${backgroundColor}`, // Add glow effect with dynamic color
                    zIndex: '99999', // Force modal to be on top of all other elements
                    cursor: 'default'

                }} onClick={(e) => { e.stopPropagation(); handleModalContentClick(e); }}>
                {/* X FOR CLOSING MODAL */}
                <div style={{
                    position: 'absolute',
                    top: '20px',
                    right: '30px',
                    fontSize: '25px',
                    cursor: 'pointer'
                }} onClick={handleClose}>
                    &#10005;
                </div>
                {/* TITLE */}
                <h1 style={{ 
                    paddingTop: '20px',
                    paddingBottom: '15px',
                     }}>{record.name}</h1>
                {/* MAIN IMAGES */}
                {record.allImageUrls.map((url, index) => (
                    <img
                        key={index}
                        src={url}
                        alt={`${record.name} - ${index + 1}`}
                        onLoad={handleImageLoaded}
                        style={{
                            //height: 'auto', 
                            margin: '10px', // Add margin around the image
                            boxShadow: `0 0 20px ${lightBackgroundColor}`, // Use lightened color for boxShadow
                            borderRadius: '8px',
                        }}
                        className='modal-image' />
                ))}
                {/* YEAR */}
                <p style={{ textAlign: "left", paddingTop: "20px" }}>
                    <strong style={{ fontWeight: '900' }}>Year:</strong><br /> {record.year}
                </p>

                {/* DESCRIPTION */}
                {record.description && (
                    <p style={{ textAlign: "left" }}>
                        <strong style={{ fontWeight: '900' }}>Description:</strong><br />{record.description}
                    </p>
                )}

                {/* COLLAGING IMAGES (FRAGMENTS) */}
                <p style={{
                    textAlign: 'left',
                    fontWeight: '900',
                }}>Fragments:</p>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    gap: '10px',
                    marginTop: '20px'
                }}>
                    {record.collagingImgUrls.map((url, index) => (
                        <div key={index}
                            className='fragment'
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={(e) => handleFragmentClick(url, index, e)}
                        >
                            <img src={url} alt={`Collaging fragment ${index + 1} for ${record.name}`} onLoad={handleImageLoaded}
                                style={{
                                    maxWidth: '150px',
                                    outline: selectedFragment?.url === url ? '1px solid black' : 'none'
                                }}
                            />
                        </div>
                    ))}
                </div>
                <button
                    onClick={handleAddToCollage}
                    disabled={!selectedFragment}
                    style={{ marginTop: '20px' }}
                    className={`btn btn-light ${!selectedFragment ? 'disabled' : ''}`}
                >
                    Add to Collage
                </button>
            </div>
        </div>
    );
}

export default SubcategoryEntityModal;


