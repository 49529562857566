import React, { useState, useRef, useEffect } from 'react';
import interact from 'interactjs';

function FragmentCarouselImage({ fragment, onDragStart }) {
  const [loaded, setLoaded] = useState(false);
  const fragmentRef = useRef(null);
  const dragHandleRef = useRef(null);

  useEffect(() => {
    if (loaded && dragHandleRef.current) {
      const dragHandler = interact(dragHandleRef.current)
        .draggable({
          inertia: false,
          listeners: {
            start(event) {
              onDragStart(event, fragment);
            },
          },
        })
        .styleCursor(false);

      return () => {
        dragHandler.unset();
      };
    }
  }, [fragment, onDragStart, loaded]);

  const handleImageLoad = (event) => {
    const { width, height } = event.target;
    if (dragHandleRef.current) {
      dragHandleRef.current.style.width = `${width}px`;
      dragHandleRef.current.style.height = `${height}px`;
    }
    setLoaded(true);
  };

  const opacity = 0.8;
  const borderRadius = '10px';

  const getColorByCategory = (category) => {
    switch (category.toLowerCase()) {
      case 'art':
        return `rgba(134, 64, 0, ${opacity})`;
      case 'context':
        return `rgba(192, 146, 125, ${opacity})`;
      case 'place':
        return `rgba(207, 96, 89, ${opacity})`;
      case 'stewards':
        return `rgba(147, 154, 121, ${opacity})`;
      case 'altar':
        return `rgba(220, 180, 82, ${opacity})`;
      default:
        return `rgba(200, 200, 200, ${opacity})`;
    }
  };

  return (
    <div
      ref={fragmentRef}
      style={{
        minWidth: '100px',
        height: '100px',
        margin: '0 10px',
        backgroundColor: loaded ? 'transparent' : 'none',
        touchAction: 'none',
        userSelect: 'none',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '60%',
          height: '60%',
          backgroundColor: getColorByCategory(fragment.category),
          pointerEvents: 'none',
          //zIndex: 0,
          borderRadius: borderRadius,
        }}
      />
      <img
        src={fragment.largeThumbnailURL}
        alt={`Fragment from ${fragment.name}`}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          display: loaded ? 'block' : 'none',
          position: 'relative',
          //zIndex: 1,
        }}
        onLoad={handleImageLoad}
      />
      {loaded && (
        <div
          ref={dragHandleRef}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%',
            height: '60%',
            cursor: 'grab',
            pointerEvents: 'auto',
            //zIndex: 2,
            borderRadius: borderRadius,
          }}
        />
      )}
    </div>
  );
}

export default FragmentCarouselImage;





