import { useState, useEffect, useCallback } from 'react';
import Airtable from 'airtable';

const useCollageFragmentDataForCarousel = () => {
    const [fragments, setFragments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchFragments = useCallback(() => {
        setLoading(true);
        const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;
        const baseId = process.env.REACT_APP_AIRTABLE_BASE_ID;
        const base = new Airtable({ apiKey }).base(baseId);
        const tableId = process.env.REACT_APP_AIRTABLE_TABLE_ID;

        base(tableId).select({
            fields: ["Name", "Collaging Images", "Record Description", "Main Photograph ", "Category", "Subcategory"]
        }).all().then(records => {
            const allImages = records.reduce((acc, record) => {
                const images = record.fields["Collaging Images"] || [];
                images.forEach((img, index) => acc.push({
                    largeThumbnailURL: img.thumbnails.large.url,
                    name: record.fields["Name"],
                    description: record.fields["Record Description"],
                    mainPhoto: record.fields["Main Photograph "] && record.fields["Main Photograph "][0] ? record.fields["Main Photograph "][0].thumbnails.large.url : null,
                    category: record.fields["Category"],
                    subcategory: record.fields["Subcategory"],
                    fragmentIndex: index
                }));
                return acc;
            }, []);

            const shuffled = allImages.sort(() => 0.5 - Math.random());
            setFragments(shuffled.slice(0, 10));
            setLoading(false);
        }).catch(err => {
            console.error('Error fetching data:', err);
            setError(err);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        fetchFragments();
    }, [fetchFragments]);

    return { fragments, loading, error, refreshFragments: fetchFragments };
};

export default useCollageFragmentDataForCarousel;



