import React from 'react';

function Offerings() {
  return (
    <div>
      <p>Offerings page</p>
    </div>
  );
}

export default Offerings;
