import React, { useEffect, useState } from 'react';
import '../index.css'

const CollapsibleSidebarItem = ({ label, children, openStateSignal, triggerSignal, colorClass }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open && !openStateSignal) {
            setOpen(false)
        }
        else if (!open && openStateSignal) {
            setOpen(true)
        }
    }, [triggerSignal]);

    const toggleOpen = () => setOpen(!open);

    const labelStyle = {
        cursor: 'pointer',
        textDecoration: 'none', // No underline by default
        userSelect: 'none'
    };

    const hoverStyle = {
        textDecoration: 'underline' // Underline on hover
    };

    const innerStyle = {
        userSelect: 'none',
    };

    return (
        <div>
            <div 
              onClick={toggleOpen} 
              style={labelStyle}
            >
                <p className={`sidebar-link ${colorClass}`} style={{margin: '0px'}}>
                    {label}
                    {open ? " ⇱" : " ⇲"}</p>
            </div>
            {open && <div className="toggle" style={innerStyle}>{children}</div>}
        </div>
    );
};

export default CollapsibleSidebarItem;

