import React from 'react';
import useCollageFragmentDataForCarousel from './useCollageFragmentDataForCarousel';
import FragmentCarouselImage from '../FragmentCarouselImage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';


function FragmentCarousel({ onDragStart }) {
  const { fragments, loading, error, refreshFragments } = useCollageFragmentDataForCarousel();

  // if (loading) return <p>Loading fragments...</p>;
  if (error) return <p>Error loading fragments: {error.message}</p>;

  return (
    <>
      <button className="btn btn-dark" onClick={refreshFragments} style={{ margin: '10px 0' }}>
        Refresh Fragments
        <FontAwesomeIcon icon={faSyncAlt} style={{ marginLeft: '8px' }} />

      </button>
      <div
        className="fragment-carousel-container"
        style={{
          position: 'relative',
          height: '120px',
          //backgroundColor: 'lightskyblue',
        }}
      >
        <div
          className="fragment-carousel"
          style={{
            display: 'flex',
            overflowX: 'auto',
            gap: '10px',
            padding: '10px 0',
            outline: '1px solid',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            height: '100%',
            width: '100%',
          }}
        >
          {fragments.map((fragment, index) => (
            <FragmentCarouselImage
              key={index}
              fragment={fragment}
              onDragStart={onDragStart}
            />
          ))}
        </div>
        <div className="fragment-carousel-scrollbar"></div>
      </div>
      <style>
        {`
          /* Hide the scrollbar for WebKit browsers (Chrome, Safari) */
          ::-webkit-scrollbar {
            display: none;
        `}
      </style>
    </>
  );
}

export default FragmentCarousel;

