import React, { useRef, useEffect, useState } from 'react';
import interact from 'interactjs';
import './index.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';


function FragmentOnCanvas({
    fragment,
    canvasRef,
    iocContainerRef,
    isSelected,
    onClickHandler,
    onPositionChange,
    onSizeChange,
    onRotationChange,
    isDraggingFragmentRef,
    isResizingFragmentRef,
    isRotatingFragmentRef,
    flipped
}) {
    //console.log('FragmentOnCanvas rendered with selected:', isSelected);

    const fragmentRef = useRef(null);
    //const isDraggingFragmentRef = useRef(false);
    //const isResizingRef = useRef(false);

    const rotationHandleRef = useRef(null);

    const movementRef = useRef(0);

    useEffect(() => {
        const interactable = interact(fragmentRef.current)
            .draggable({
                onstart: () => {
                    isDraggingFragmentRef.current = true;
                    movementRef.current = 0;
                    console.log('onstart called with isSelected:', isSelected);
                },
                onmove: event => {
                    const target = event.target;
                    const x = Math.max((parseFloat(target.getAttribute('data-x')) || 0) + event.dx, 0);
                    const y = Math.max((parseFloat(target.getAttribute('data-y')) || 0) + event.dy, 0);

                    target.style.left = `${x}px`;
                    target.style.top = `${y}px`;
                    target.setAttribute('data-x', x);
                    target.setAttribute('data-y', y);

                    movementRef.current += Math.abs(event.dx) + Math.abs(event.dy);

                    onPositionChange(x, y);
                },
                onend: () => {
                    isDraggingFragmentRef.current = false;
                },
                modifiers: [
                    interact.modifiers.restrict({
                        restriction: iocContainerRef.current,
                        endOnly: false,
                        elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
                    })
                ]
            })
            .resizable({
                edges: { top: true, left: true, bottom: true, right: true },
                listeners: {
                    start: () => {
                        isResizingFragmentRef.current = true;
                        movementRef.current = 0;
                    },
                    move(event) {
                        const target = event.target;
                        const x = Math.max((parseFloat(target.getAttribute('data-x')) || 0) + event.deltaRect.left, 0);
                        const y = Math.max((parseFloat(target.getAttribute('data-y')) || 0) + event.deltaRect.top, 0);

                        //const aspectRatio = fragmentRef.current.offsetWidth / fragmentRef.current.offsetHeight;
                        // Calculate the aspect ratio
                        const aspectRatio = fragment.width / fragment.height;

                        let newWidth = event.rect.width;
                        let newHeight = event.rect.height;

                        if (event.edges.left || event.edges.right) {
                            newHeight = newWidth / aspectRatio;
                        } else if (event.edges.top || event.edges.bottom) {
                            newWidth = newHeight * aspectRatio;
                        }

                        const canvasWidth = canvasRef.current.offsetWidth;
                        const maxWidth = canvasWidth - x;

                        const canvasHeight = canvasRef.current.offsetHeight;
                        const maxHeight = canvasHeight - y;

                        if (newWidth > maxWidth) {
                            newWidth = maxWidth;
                            newHeight = newWidth / aspectRatio;
                        }

                        if (newHeight > maxHeight) {
                            newHeight = maxHeight;
                            newWidth = newHeight * aspectRatio;
                        }

                        //const newX = x + newWidth;
                        //const newY = y + newHeight / 2;


                        target.style.width = `${newWidth}px`;
                        target.style.height = `${newHeight}px`;
                        target.style.left = `${x}px`;
                        target.style.top = `${y}px`;

                        target.setAttribute('data-x', x);
                        target.setAttribute('data-y', y);

                        movementRef.current += Math.abs(event.deltaRect.left) + Math.abs(event.deltaRect.top);

                        onSizeChange(newWidth, newHeight, x, y);
                    },
                    end: () => {
                        isResizingFragmentRef.current = false;
                    }
                },
                modifiers: [
                    interact.modifiers.aspectRatio({
                        ratio: 'preserve',
                        modifiers: [
                            interact.modifiers.restrictEdges({
                                outer: iocContainerRef.current,
                            }),
                        ],
                    }),
                    // interact.modifiers.aspectRatio({
                    //     ratio: 'preserve'
                    // }),
                    // interact.modifiers.restrictEdges({
                    //     outer: iocContainerRef.current,
                    // }),
                    interact.modifiers.restrictSize({
                        min: { width: 80, height: 80 },
                    })
                ]
            });

        return () => {
            interactable.unset();

        };
    }, [canvasRef]);

    useEffect(() => {
        let rotationInteractable = null;

        if (isSelected && rotationHandleRef.current) {
            rotationInteractable = interact(rotationHandleRef.current)
                .draggable({
                    onstart: (event) => {
                        isRotatingFragmentRef.current = true;
                        const rect = fragmentRef.current.getBoundingClientRect();
                        const centerX = rect.left + rect.width / 2;
                        const centerY = rect.top + rect.height / 2;
                        fragmentRef.current.setAttribute('data-center-x', centerX);
                        fragmentRef.current.setAttribute('data-center-y', centerY);
                    },
                    onmove: (event) => {
                        const centerX = parseFloat(fragmentRef.current.getAttribute('data-center-x'));
                        const centerY = parseFloat(fragmentRef.current.getAttribute('data-center-y'));
                        const angle = Math.atan2(event.clientY - centerY, event.clientX - centerX) * 180 / Math.PI;

                        // Apply both rotation and flip transformations
                        fragmentRef.current.style.transform = `rotate(${angle}deg)`;
                        onRotationChange(angle);
                    },
                    onend: () => {
                        isRotatingFragmentRef.current = false;
                    },
                });
        }

        return () => {
            if (rotationInteractable) {
                rotationInteractable.unset();
            }
        };
    }, [isSelected, rotationHandleRef]);


    const handleClick = () => {
        console.log('handleClick called with isSelected:', isSelected);
        if (!isDraggingFragmentRef.current && !isResizingFragmentRef.current && movementRef.current <= 3) {
            console.log('onClickHandler called with isSelected:', isSelected);
            onClickHandler();
        }
        movementRef.current = 0;
    };


    const handleTouchStart = (event) => {
        event.preventDefault();
    };

    return (
        <div
            ref={fragmentRef}
            className="draggable-fragment fade-in"
            style={{
                position: 'absolute',
                cursor: 'move',
                outline: isSelected ? '1px solid red' : 'none',
                opacity: 0,
                touchAction: 'none',
                userSelect: 'none',
                width: `${fragment.width}px`,
                height: `${fragment.height}px`,
                left: `${fragment.x}px`,
                top: `${fragment.y}px`,
                zIndex: fragment.zIndex,
                transform: `rotate(${fragment.rotationAngle || 0}deg)`

            }}
            data-x={fragment.x}
            data-y={fragment.y}
            onClick={handleClick}
            onMouseOver={() => fragmentRef.current.style.outline = isSelected ? '1px solid red' : '1px solid'}
            onMouseOut={() => fragmentRef.current.style.outline = isSelected ? '1px solid red' : 'none'}
            onTouchStart={handleTouchStart}
        >
            <img
                src={fragment.largeThumbnailURL}
                className='fragment-image-img'
                alt=""
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    transform: `${fragment.flipped ? 'scaleX(-1)' : ''}`,
                }}
            />
            {isSelected && (
                <div
                    className='rotation-handle-container'
                    ref={rotationHandleRef}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '100%',
                        transform: 'translate(10px, -50%)',
                        cursor: 'grab',
                        zIndex: fragment.zIndex + 1,
                        padding: '5px',
                        backgroundColor: 'red',
                        borderRadius: '20%',
                    }}
                >
                    <FontAwesomeIcon
                        className="rotation-handle"
                        icon={faRotate}
                    />
                </div>
            )}
            {/* <p
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                }}
            >
                {fragment.rotationAngle}{isSelected ? 'Selected' : 'not'}
            </p> */}
        </div>
    );

}

export default FragmentOnCanvas;



