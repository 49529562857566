import React from 'react';

function About() {
  return (
    <div>
        <p>About page</p>
    </div>
  );
}

export default About;
